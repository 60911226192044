<template>
  <vs-card>
    <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>

    <div>
      <label for="publisher" class="input-control mr-3 mb-0">Publisher</label>
    </div>
    <select v-model="selectedPublisher" id="publisher" class="form-control">
      <option
        v-for="publisher in publishers"
        :value="publisher.id"
        :key="publisher.id"
        >{{ publisher.name }}</option
      >
    </select>

    <b-form class="p-8" @submit.prevent="onSubmit">
      <b-form-group v-for="(type, key) in schema" :key="key"
        class="mt-2"
        :id="key"
        :label="key"
      >
        <component :is="dynamicFieldComponent(type)" :value="form[key]"/>
      </b-form-group>

      <b-button type="submit" variant="primary" class="mt-8" :disabled="inProgress">Create Ad Unit</b-button>
    </b-form>
  </vs-card>
</template>

<script>
import { PUBLISHERS } from "../../../utils/product_entities";
import { dynamicFieldComponent } from "../../../utils/prebid_config";

export default {
  components: {},
  data() {
    return {
      schema: null,
      form: {},
      inProgress: false,
      submitError: null,
      dynamicFieldComponent: dynamicFieldComponent,
      publishers: PUBLISHERS,
      selectedPublisher: null,
    };
  },
  mounted() {
    this.selectedPublisher = this.$route.query.publisher_id;
    this.$http.get(`${this.host}/settings/prebid_ad_units_schema`).then(res => {
      this.schema = res.data.schema;
      Object.keys(this.schema).forEach( key => {
        this.form[key] = { value: null }
      });
    });
  },
  methods: {
    onSubmit() {
      this.submitError = null;
      this.inProgress = true;
      this.$http
        .post(
          `${this.host}/settings/prebid_ad_units_create`,
          { publisher_id: this.selectedPublisher, ad_unit: this.form }
        )
        .then(() => {
          this.$router.push({
            path: "/settings/prebid/adUnits",
            query: { publisher_id: this.selectedPublisher }
          });
        })
        .catch(ex => {
          this.submitError = ex.response.data.error;
        }).finally(() => {
          this.inProgress = false;
        });
    }
  }
};
</script>
