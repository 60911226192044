<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                style="filter: invert(100%)"
                src="@/assets/full_logo.svg"
                alt="login"
                class="mx-auto px-10"
              />
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div
                class="px-8 pt-8 login-tabs-container d-flex flex-column justify-center"
              >
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                  <div id="gis_signin_btn" class="g_id_signin" data-type="standard" :style="`width: ${loginButtonWidth}px`"></div>

                  <div style="text-align: center; transform: translate(-12px);">
                    <a :href="`${this.host}/oauth/authenticate_okta`">
                      <img
                        src="../components/images/signin-okta.png"
                        :style="`width: ${loginButtonWidth}px`"
                      />
                    </a>
                  </div>

                <div v-if="submitError" class="alert alert-danger mt-4">
                  {{ submitError }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      params: {
        client_id: '339123310524-ctg8mfrd39dnkj1l7n85b0egb8ngo76a.apps.googleusercontent.com',
      },
      loginButtonWidth: 250,
      submitError: null,
    };
  },

  beforeCreate(){
    loadGSI();
  },

  mounted() {
    window.addEventListener('load', () => {
      const { google } = window;
      google.accounts.id.initialize({
        client_id: this.params.client_id,
        callback: this.onSignInSuccess
      });
      const parent = document.getElementById('gis_signin_btn');
      google.accounts.id.renderButton(parent, { theme: "outline" });
    });

    if (this.$route.query.t && this.$route.query.t != "")
      this.onOktaSuccess(this.$route.query.t);
  },
  methods: {
    onOktaSuccess(token) {
      axios.post(`${this.host}/users/signin`, { token: token, provider: "okta" }).then((res) => {
        localStorage.setItem("token", res.data.token);
        let user = JSON.stringify(res.data.user);
        localStorage.setItem("user", user);
        localStorage.setItem("user-image", "");
        this.$store.commit("UPDATE_USER_IMAGE", "");

        this.$http.defaults.headers.common["Authorization"] = localStorage.getItem("token");
        this.$store.commit("UPDATE_USER_INFO", user);
        this.$router.push({ name: "dashboard" });
      });
    },
    async onSignInSuccess(data) {
      axios.post(`${this.host}/users/signin`, { token: data.credential })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          let user = JSON.stringify(res.data.user);
          localStorage.setItem("user", user);

          if (data.w3) {
            localStorage.setItem("user-image", data.w3.Paa);
            this.$store.commit("UPDATE_USER_IMAGE", data.w3.Paa);
          }

          this.$http.defaults.headers.common["Authorization"] =
            localStorage.getItem("token");
          this.$store.commit("UPDATE_USER_INFO", user);
          this.$router.push({ name: "dashboard" });
        })
        .catch((err) => {
          this.submitError = (err.response ? err.response.data : err).message;
        });
    },
  },
  components: {},
};

function loadGSI() {
  const gsiScript = document.createElement('script');
    gsiScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
    gsiScript.setAttribute('async', true);
    gsiScript.setAttribute('defer', true);
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(gsiScript);
}
</script>

<style scoped lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }

  $white: #fff;
  $google-blue: #4285f4;
  $button-active-blue: #1669f2;

  .google-btn {
    margin-left: 12px;
    cursor: pointer;
    width: 184px;
    height: 42px;
    background-color: $google-blue;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: $white;
    }
    .google-icon {
      position: absolute;
      margin-top: 11px;
      margin-left: 11px;
      width: 18px;
      height: 18px;
    }
    .btn-text {
      float: right;
      margin: 11px 11px 0 0;
      color: $white;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-family: "Roboto";
    }
    &:hover {
      box-shadow: 0 0 6px $google-blue;
    }
    &:active {
      background: $button-active-blue;
    }
  }
}
</style>
