<template>
  <vs-card>
    <div :class="feedbackObject.class">{{feedbackObject.text}}</div>
    <div class="card p-2">
      <div class="d-flex justify-content-between">
        <CustomFilter :supportedFilters="supportedFilters" :customFilters="customFilters" @filterApplied="filterApplied">
          <b-button class="btn-sm mx-1" variant="danger" @click="getData()" :disabled="isGettingData">
            <i :class="`fas fa-sync ${isGettingData ? 'fa-spin' : ''}`"></i>
          </b-button>
        </CustomFilter>
      </div>
    </div>
    <div class="card p-2 d-flex flex-row">
            <div class="d-flex align-items-center">
          <FormSubmitter ref="form"
                        class="form"
                        :path="`adsets/${adset.id}/update_budgets`"
                        method="put"
                        :feedbackObject="feedbackObject"
                        :disabled="campaign.cpc < 0.01"
            >
            Campaign CPC:
            <input name="attribute" type="hidden" value="max_bid">
            <span>
              <b-form-input size="sm" name="value" v-model="campaign.cpc" type="number" class="d-inline pt-0" style="width: 5rem" step="0.005"/>
            </span>
          </FormSubmitter>
          <div v-if="isSmart" class="reddish alert alert-danger smart-bid-campaign-btn">SMART BID CAMPAIGN</div>
        </div>
        <div class="vertical-line"></div>
        <MultiSelection class="d-flex" :multiSelect="multiSelect" >
          <div class="action-row d-flex">
            <MultiCpcUpdate v-if="campaign.cpc"
                            :feedbackObject="feedbackObject"
                            :siteIds="multiSelect.selectedItems"
                            :baseBid="parseFloat(campaign.cpc)"
                            :campaignId="entity.id"
                            :suggestedBids="suggestedBids"
                            :modalSelectedDate="modalSelectedDate"
                            @onSuccess="getData"/>
            <div class="vertical-line" v-if="campaign.cpc"></div>
          </div>
          <div class="action-row d-flex flex-wrap">
            <NetworkButton class="btn-red float-left mr-2"
                          :path="`campaigns/${entity.id}/site_update_blacklist`"
                          @clicked="multiBlockPayload"
                          :feedbackObject="feedbackObject"
                          :showPending="true"
                          :disabled="nothingSelected"
                          @onSuccess="updateBlacklisted"
            >Block Selected
            </NetworkButton>
            <NetworkButton class="btn-green float-left ml-1"
                          :path="`campaigns/${entity.id}/site_update_blacklist`"
                          @clicked="multiUnBlockPayload"
                          :feedbackObject="feedbackObject"
                          :disabled="nothingSelected"
                          :showPending="true"
                          variant="success"
                          @onSuccess="updateBlacklisted"
            >Unblock Selected
            </NetworkButton>
            <div class="vertical-line"></div>
            <span class="btn-red float-left ml-1">
              <NetworkButton :path=           "`campaigns/${entity.id}/site_update_blacklist`"
                            @clicked=        "multiAccountBlockPayload"
                            :disabled=       "nothingSelected"
                            @onSuccess=      "updateAccountBlacklisted"
                            :showPending=    "true"
                            :feedbackObject= "feedbackObject"
              >Block Selected On Account Level
              </NetworkButton>
            </span>
            <span class="btn-red float-left ml-2 mr-2">
              <b-button class="b-button" v-if="!blockPresetVisible" @click="blockPresetVisible = true" variant="danger" size="sm" :disabled="nothingSelected">
                Add Selected To Site Block Preset
              </b-button>
              <span v-if="blockPresetVisible" class="ml-1">
                <SiteBlacklistPresets
                  :feedbackObject="feedbackObject"
                  :selectedSlugs="multiSelect.selectedItems"
                />
              </span>
              <b-button class="b-button ml-1" v-if="blockPresetVisible" @click="blockPresetVisible = false" variant="danger" size="sm">
                Cancel
              </b-button>
            </span>
          </div>
        </MultiSelection>
    </div>
    <div class="card p-2 d-flex flex-row">
      <div class="d-flex total">Total for filter: {{filteredSlugs.length}}. Selected: {{multiSelect.selectedItems.length}}</div>
      <b-button class="btn-sm mx-1 btn-light-green" variant="outline-primary" @click="toggleSelectionAll">Select {{nothingSelected ? 'All' : 'None'}}</b-button>
    </div>
    <div class="card p-2 d-flex flex-row">
      <input class="m-1 ml-0 w-25 search-input" type="text" v-on:keyup.enter="doSearch" v-model="searchRgx" placeholder="Search site by Id / Name">
    </div>
    <table class="text-left table table-bordered table-responsive-md">
      <thead>
      <tr>
        <th v-if="multiSelect.isOpen" class="w1per">
          <MasterCheckbox v-model="multiSelect"/>
        </th>
        <th class="clickable" style="width: 10rem; min-width: 10rem" @click="sortBy('name')">{{dirArrow('name')}}Name</th>
        <th class="clickable w1per" @click="sortBy('blocked')">{{dirArrow('blocked')}}<i class="fas fa-ban"/></th>
        <th class="w1per" style="min-width: 11rem">
          <div>
            Site Bid {{campaign.cpc}}
            <button class="px-1 float-right" :disabled="isGettingData || Object.values(this.modifiedBids).length === 0" v-on:click="applyModifications()">Apply</button>
          </div>
        </th>
        <th class="clickable w1per" @click="sortBy('suggested_bid')" style="min-width: 6rem">{{dirArrow('suggested_bid')}}Suggested Bid</th>
        <th class="clickable w1per" @click="sortBy('visit_value')">{{dirArrow('visit_value')}}VV</th>
        <th class="clickable w1per" @click="sortBy('cv')" style="min-width: 6rem">{{dirArrow('cv')}}CV</th>
        <th class="clickable w1per" @click="sortBy('session_value')">{{dirArrow('session_value')}}SV</th>
        <th class="clickable w1per" @click="sortBy('sps')">{{dirArrow('sps')}}CPC</th>
        <th class="clickable w1per" @click="sortBy('actual_cpc')">{{dirArrow('actual_cpc')}}Actual CPC</th>
        <th class="clickable w1per" @click="sortBy('impressions')">{{dirArrow('impressions')}}Imps*</th>
        <th class="clickable w1per" @click="sortBy('visible_impressions')">{{dirArrow('visible_impressions')}}vImps*</th>
        <th class="clickable w1per" @click="sortBy('clicks')">{{dirArrow('clicks')}}Clicks*</th>
        <th class="clickable w1per" @click="sortBy('landings')">{{dirArrow('landings')}}Visits</th>
        <th class="clickable w1per" @click="sortBy('visits_60m')">{{dirArrow('visits_60m')}}Visits 60m</th>
        <th class="clickable w1per" @click="sortBy('sessions')">{{dirArrow('sessions')}}Sessions</th>
        <th class="clickable w1per" @click="sortBy('bounce_rate')">{{dirArrow('bounce_rate')}}Bounce %</th>
        <th class="clickable w1per" @click="sortBy('units')" style="min-width: 7rem">{{dirArrow('units')}}Items</th>
        <th class="clickable w1per" @click="sortBy('ads_per_item')">{{dirArrow('ads_per_units')}}Ads Per Item</th>
        <th class="clickable w1per" @click="sortBy('ppl')">{{dirArrow('ppl')}}Ads Per Visit</th>
        <th class="clickable w1per" @click="sortBy('ppv')">{{dirArrow('ppv')}}Ads Per Session</th>
        <th class="clickable w1per" @click="sortBy('revenue')">{{dirArrow('revenue')}}Revenue</th>
        <th class="clickable w1per" @click="sortBy('profit')">{{dirArrow('profit')}}Profit</th>
        <th class="clickable w1per" @click="sortBy('predicted_adx')">{{dirArrow('predicted_adx')}}Pred. ADX & OB</th>
        <th class="clickable w1per" @click="sortBy('predicted_revenue')">{{dirArrow('predicted_revenue')}}Est Revenue</th>
        <th class="clickable w1per" @click="sortBy('est_spend')">{{dirArrow('est_spend')}}Est Spend</th>
        <th class="clickable w1per" @click="sortBy('spent')">{{dirArrow('spent')}}Spend*</th>
        <th class="clickable w1per" @click="sortBy('predicted_profit')">{{dirArrow('predicted_profit')}}Est Profit</th>
        <th class="clickable w1per" @click="sortBy('predicted_margin')">{{dirArrow('predicted_margin')}}Est Margin</th>
        <th class="clickable w1per" @click="sortBy('rpm')">{{dirArrow('rpm')}}RPM</th>
        <th class="clickable w1per" @click="sortBy('margin')">{{dirArrow('margin')}}Margin</th>
        <th class="clickable w1per" @click="sortBy('roas')">{{dirArrow('roas')}}ROAS %</th>
        <th class="clickable w1per" @click="sortBy('margin_60m')">{{dirArrow('margin_60m')}}Margin 60m</th>
        <th class="clickable w1per" @click="sortBy('vcpm')">{{dirArrow('vcpm')}}vCPM</th>
        <th class="clickable w1per" @click="sortBy('ctr')">{{dirArrow('ctr')}}CTR*</th>
        <th class="clickable w1per" @click="sortBy('vctr')">{{dirArrow('vctr')}}vCTR*</th>
        <th class="clickable w1per" @click="sortBy('cpa_conversion_rate')">{{dirArrow('cpa_conversion_rate')}}CPA Conv.</th>
        <th/>
      </tr>
      <tr class="totals">
        <th colspan="5"/>
        <th>{{ (totals_row.visit_value || 0.0).toFixed(3)                       }}</th>
        <th>{{ (totals_row.cv || 0.0).toFixed(3)                                }}</th>
        <th>{{ (totals_row.session_value || 0.0).toFixed(3)                     }}</th>
        <th>{{ (totals_row.sps || 0.0).toFixed(3)                               }}</th>
        <th>{{ (totals_row.actual_cpc || 0.0).toFixed(2)                        }}</th>
        <th>{{ (totals_row.impressions || 0).toLocaleString()                   }}</th>
        <th>{{ (totals_row.visible_impressions || 0).toLocaleString()           }}</th>
        <th>{{ (totals_row.clicks || 0).toLocaleString()                        }}</th>
        <th>{{ (totals_row.landings || 0).toLocaleString()                      }}</th>
        <th>{{ (totals_row.visits_60m || 0).toLocaleString()                    }}</th>
        <th>{{ (totals_row.sessions || 0).toLocaleString()                      }}</th>
        <th>{{ (totals_row.bounce_rate || 0).toFixed(0)                        }}%</th>
        <th>{{ (totals_row.units_display || 0)                                  }}</th>
        <th>{{ (totals_row.ads_per_item || 0).toFixed(2)                        }}</th>
        <th>{{ (totals_row.ppl || 0.0).toFixed(2)                               }}</th>
        <th>{{ (totals_row.ppv || 0.0).toFixed(2)                               }}</th>
        <th>{{ (totals_row.revenue || 0).toFixed(2)                             }}</th>
        <th>{{ (totals_row.profit || 0).toFixed(2)                              }}</th>
        <th>{{ (totals_row.predicted_adx || 0).toFixed(2)                       }}</th>
        <th>{{ (totals_row.predicted_revenue || 0).toFixed(2)                   }}</th>
        <th>{{ isSmart ? 'N/A' : (totals_row.est_spend || 0).toFixed(2)         }}</th>
        <th>{{ (totals_row.spent || 0).toFixed(2)                               }}</th>
        <th v-bind:class="posNegColor(totals_row.predicted_profit || 0)">{{ isSmart ? 'N/A' : (totals_row.predicted_profit || 0).toFixed(2) }}</th>
        <th>{{ isSmart ? 'N/A' : (totals_row.predicted_margin || 0).toFixed(2) }}%</th>
        <th>{{ (totals_row.rpm || 0).toFixed(2)                                 }}</th>
        <th>{{ isSmart ? 'N/A' : (totals_row.margin || 0.0).toFixed(2)         }}%</th>
        <th>{{ (totals_row.roas || 0)                                          }}%</th>
        <th>{{ isSmart ? 'N/A' : (totals_row.margin_60m || 0.0).toFixed(2)     }}%</th>
        <th>{{ (totals_row.vcpm || 0).toFixed(2)                                }}</th>
        <th>{{ (totals_row.ctr || 0).toFixed(2)                                 }}</th>
        <th>{{ (totals_row.vctr || 0).toFixed(2)                                }}</th>
        <th/>
        <th/>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item.slug" v-bind:class="{ blocked: item.blocked || item.acc_blocked }">
        <td v-if="multiSelect.isOpen">
          <input :id="`chk-${item.slug}`" type="checkbox" :value="item.slug" v-model="multiSelect.selectedItems">
        </td>
        <td style="text-align: left !important; padding: 0 !important; max-width: 1px">
          <label :for="`chk-${item.slug}`" class="pl-1" v-bind:class="{ ['text-muted']: item.blocked }">
            <span class="name-cel" :title="item.name">{{ item.name }}</span>
            <span>{{item.ext_id}}</span>
            <span v-if="item.acc_blocked" class="text-danger ml-1">Account Blocked</span>
          </label>
        </td>
        <td>
          <NetworkButton :path="`campaigns/${entity.id}/site_update_blacklist`"
                         :payload="{ payload: { target_sites: [item.slug], block: !item.blocked }, site: item}"
                         :feedbackObject="feedbackObject"
                         :disabled="false"
                         :showPending="true"
                         :variant="item.blocked ? 'danger' : 'white'"
                         @onSuccess="item.blocked = !item.blocked"
          ><i class="fas" v-bind:class="blockedButton(item)"/>
          </NetworkButton>
        </td>
        <td>
          <div>
            <button class="px-0 float-left" :disabled="isGettingData || item.blocked || item.cpc_modification <= 10 || reachedMinimum(item)" v-on:click="updateModification(item, -10)">-10%</button>
            {{ bidLabel(item) }}
            <button class="px-0 float-right" :disabled="isGettingData || item.blocked || item.cpc_modification >= 200" v-on:click="updateModification(item, 10)">+10%</button>
          </div>
        </td>
        <td>
          <button class="px-0 pl-1 float-left" :disabled="isGettingData || item.blocked || !canBoost(item)" v-on:click="setSuggested(item)">{{boostLabel(item)}}</button>
          {{ item.suggested_bid.toFixed(3) }}
        </td>
        <td>{{ item.visit_value.toFixed(3)                         }}</td>
        <td>{{ item.cv.toFixed(3)                                  }}</td>
        <td>{{ item.session_value.toFixed(3)                       }}</td>
        <td>{{ isSmart ? 'N/A' : item.sps.toFixed(3)               }}</td>
        <td>{{ item.actual_cpc.toFixed(2)                          }}</td>
        <td>{{ item.impressions                                    }}</td>
        <td>{{ item.visible_impressions                            }}</td>
        <td>{{ item.clicks                                         }}</td>
        <td>{{ item.landings                                       }}</td>
        <td>{{ item.visits_60m                                     }}</td>
        <td>{{ item.sessions                                       }}</td>
        <td>{{ item.bounce_rate                                   }}%</td>
        <td>
          <UnitsTooltip :campaign_id="entity.id" :content="item.units_display" :utm_term="item.slug" :timezone="campaignTimezone" :start="modalSelectedDate.start" :end="modalSelectedDate.end"/>
        </td>
        <td>{{ item.ads_per_item.toFixed(2)                        }}</td>
        <td>{{ item.ppl.toFixed(2)                                 }}</td>
        <td>{{ item.ppv.toFixed(2)                                 }}</td>
        <td>{{ item.revenue.toFixed(2)                             }}</td>
        <td>{{ item.profit.toFixed(2)                              }}</td>
        <td>{{ item.predicted_adx.toFixed(2)                       }}</td>
        <td>{{ item.predicted_revenue.toFixed(2)                   }}</td>
        <td>{{ isSmart ? 'N/A' : item.est_spend.toFixed(2)         }}</td>
        <td>{{ item.spent.toFixed(2)                               }}</td>
        <td v-bind:class="posNegColor(item.predicted_profit)">{{ isSmart ? 'N/A' : item.predicted_profit.toFixed(2) }}</td>
        <td>{{ isSmart ? 'N/A' : item.predicted_margin.toFixed(2) }}%</td>
        <td>{{ item.rpm.toFixed(2)                                 }}</td>
        <td>{{ isSmart ? 'N/A' : item.margin.toFixed(2)           }}%</td>
        <td>{{ item.roas.toFixed(0)                               }}%</td>
        <td>{{ isSmart ? 'N/A' : item.margin_60m.toFixed(2)       }}%</td>
        <td>{{ item.vcpm.toFixed(2)                                }}</td>
        <td>{{ item.ctr.toFixed(2)                                 }}</td>
        <td>{{ item.vctr.toFixed(2)                                }}</td>
        <td>{{ item.cpa_conversion_rate                            }}</td>
        <td/>
      </tr>
      <tr>
        <td colspan="10">
          <b-pagination pills
                        :limit="13"
                        size='sm'
                        v-model="page"
                        align="center"
                        :total-rows="filteredSlugs.length"
                        :per-page="params.page_size"
                        first-number
                        last-number
                        :disabled="isGettingData"
                        v-bind:class="{ flicker: isGettingData }"
          >
            <template v-slot:ellipsis-text>
              <span v-if="isGettingData"><b-spinner small></b-spinner></span>
            </template>
          </b-pagination>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
    </div>
  </vs-card>
</template>

<script>
  import MultiCpcUpdate       from './taboola/MultiCpcUpdate.vue';
  import { datePickerRanges } from "../utils/values.js";
  import UnitsTooltip         from '../components/overview/UnitsTooltip';

  export default {
    components: { MultiCpcUpdate,  UnitsTooltip },
    props: ['entityType', 'entity', 'modalSelectedDate', 'campaignTimezone'],
    data() {
      return {
        bid:            0,
        sort:           { field: 'landings', direction: -1 },
        page:           1,
        range:          [],
        items:          [],
        adset:          {},
        params:         { page_size: 50 },
        isSmart:        false,
        campaign:       {},
        searchRgx:      null,
        totals_row:     {},
        multiSelect:    { isOpen: true, selectedItems: [], sourceItems: [] },
        modifiedBids:   {},
        blockPresets:   [],
        filteredSlugs:  [],
        customFilters:  [],
        isGettingData:  false,
        suggestedBids:  {},
        feedbackObject: { class: '', text: '', timestamp: null },
        blockPresetVisible: false,
        rangeOptions: {
          alwaysShowCalendars: true,
          autoApply: true,
          ranges: datePickerRanges(),
        },
        supportedFilters:
          {
            metrics: {
              leftOperands:   [
                'landings', 'visit_value', 'session_value', 'sessions', 'clicks', 'suggested_bid', 'bounce_rate',
                'impressions', 'visible_impressions', 'spent', 'revenue', 'ctr', 'rpm', 'cpa_conversion_rate',
                'profit', 'predicted_adx', 'predicted_revenue', 'predicted_spent', 'est_spend', 'predicted_profit',
                'predicted_margin', 'blocked', 'margin', 'roas', 'margin_60', 'ppl', 'ppv', 'vctr', 'cv', 'ads_per_units'
              ],
              operators:      ['>=', '>', '==', '<', '<=', '!='],
              labels:         { spent: 'spend', landings: 'visits' },
              rightOperands:  null
            },
            sites: {
              leftOperands:   ['name', 'ext_id'],
              operators:      ['==', 'rgx'],
              labels:         {},
              rightOperands:  null
            }
          }
      }
    },
    computed: {
      nothingSelected: function() {
        return this.multiSelect.selectedItems.length === 0;
      }
    },
    mounted() {
      this.getDates()
    },
    methods: {
      getData(resetSelected) {
        if (this.isGettingData) return;

        this.params.page    = this.page - 1;
        this.params.sort    = this.sort;
        this.params.filters = this.customFilters.filter(f=>f.right_operand);
        this.isGettingData  = true;
        this.$http.post(`${this.host}/campaigns/${this.entity.id}/sites`, this.params)
          .then(res => {
            this.items          = res.data.items;
            this.adset          = res.data.campaign.adset;
            this.isSmart        = res.data.campaign.bid_type === 'OPTIMIZED_CONVERSIONS';
            this.campaign       = res.data.campaign;
            this.totals_row     = res.data.totals_row;
            this.modifiedBids   = {};
            this.filteredSlugs  = res.data.filtered_slugs;
            this.suggestedBids  = res.data.suggested_bids;

            this.multiSelect.sourceItems = this.items.map(i=>i.slug);
            this.resetFeedbackObject();

            if (resetSelected) this.multiSelect.selectedItems = []
          })
          .catch(res => {
            let message = res.message;
            if (res.response && res.response.data && res.response.data.error)
              message = res.response.data.error;
            this.resetFeedbackObject(message, 'error')
          })
          .finally(() => this.isGettingData = false)
      },
      doSearch() {
        this.customFilters = this.customFilters.filter(f=>f.type !== 'sites');
        if (this.searchRgx){
          let leftOperand = this.searchRgx.match(/\D/) ? 'name' : 'ext_id';
          if (leftOperand === 'ext_id')
            this.customFilters = [];
          this.customFilters.push({
            type:           'sites',
            left_operand:   leftOperand,
            operator:       leftOperand === 'name' ? 'rgx' : '==',
            right_operand:  this.searchRgx
          })
        }
        this.getData()
      },
      blockedButton(item) {
        let obj = {};
        if (item.blocked) {
          obj['fa-ban']           = true;
          obj['fa-check-circle']  = false;
        }
        else {
          obj['fa-ban']           = false;
          obj['fa-check-circle']  = true;
        }
        return obj
      },
      updateBlacklisted(payload) {
        this.items.forEach(item => {
          if (payload.target_sites.includes(item.slug)) item.blocked = payload.block
        })
      },
      updateAccountBlacklisted(payload) {
        this.items.forEach(item => {
          if (payload.target_sites.includes(item.slug)) item.acc_blocked = payload.acc_blocked;
        })
        this.getData();
      },
      filterApplied() {
        this.getData(true);
      },
      multiBlockPayload(networkRequest) {
        networkRequest.payload = { target_sites: this.multiSelect.selectedItems, block: true }
      },
      multiUnBlockPayload(networkRequest) {
        networkRequest.payload = { target_sites: this.multiSelect.selectedItems, block: false }
      },
      multiAccountBlockPayload(networkRequest) {
        networkRequest.payload = { target_sites: this.multiSelect.selectedItems, block: true, level: 'account' }
      },
      applyModifications() {
        if (this.isGettingData) return;

        this.isGettingData  = true;
        this.$http.post(`${this.host}/campaigns/${this.entity.id}/update_sites_mods`, { mods: Object.values(this.modifiedBids) })
          .then(() => {
            this.items.forEach(site => site.original_cpc_mod = site.cpc_modification);
            this.modifiedBids = {};
            this.resetFeedbackObject()
          })
          .catch(res => this.resetFeedbackObject(res.response.data.error, 'error'))
          .finally(() => this.isGettingData = false)
      },
      getDates() {
        this.params.start = this.modalSelectedDate.start
        this.params.end   = this.modalSelectedDate.end
        this.getData(true);
      },
      bidLabel(item) {
        return `${item.cpc_modification}% (${item.actual_bid.toFixed(4)})`
      },
      setSuggested(item) {
        item.cpc_modification = parseInt((item.suggested_bid / this.campaign.cpc) * 100);
        this.validateModification(item)
      },
      reachedMinimum(item) {
        return item.actual_bid <= 0.01
      },
      boostLabel(item) {
        return `Set ${item.suggested_bid <= item.actual_bid ? '↓' : '↑'}`
      },
      setActualBid(item) {
        item.actual_bid = this.campaign.cpc * item.cpc_modification / 100
      },
      updateModification(item, value) {
        item.cpc_modification += value;
        this.validateModification(item)
      },
      validateModification(item) {
        if (item.cpc_modification === item.original_cpc_mod)
          delete this.modifiedBids[item.slug];
        else
          this.modifiedBids[item.slug] = {
            new_cpc:      item.cpc_modification,
            target_site:  item.slug,
            original_cpc: item.original_cpc_mod
          };
        if (item.cpc_modification > 200) item.cpc_modification = 200;
        else if (item.cpc_modification < 1) item.cpc_modification = 1;
        this.setActualBid(item);
        if (this.reachedMinimum(item)) item.cpc_modification = parseInt((0.01 / this.campaign.cpc) * 100);
        this.setActualBid(item);
      },
      canBoost(item) {
        return item.suggested_bid >= 0.01
      },
      sortBy(field) {
        if (this.isGettingData) return;

        if (this.sort.field === field)
          this.sort.direction = this.sort.direction * -1;
        else
          this.sort.field = field;
      },
      dirArrow(field) {
        if (this.sort.field !== field) return '';

        return this.sort.direction === 1 ? '▲' : '▼'
      },
      attentionClass(item) {
        return { greenish: item.cpc_modification > 100, reddish: item.cpc_modification < 100, flicker: item.cpc_modification !== item.original_cpc_mod, blocked: item.blocked }
      },
      posNegColor(number) {
        return { greenish: number > 0, reddish: number < 0 }
      },
      resetFeedbackObject(text = '', clas = 'success') {
        this.feedbackObject.text  = text;
        this.feedbackObject.class = clas;
      },
      toggleSelectionAll() {
        if (this.multiSelect.selectedItems.length > 0) {
          this.multiSelect.selectedItems = [];
          return;
        }
        this.filteredSlugs.forEach(slug => this.multiSelect.selectedItems.push(slug));
      }
    },
    watch: {
      sort: {
        deep: true,
        handler() {
          this.getData(true);
        }
      },
      page: function() {
        this.getData();
      },
      modalSelectedDate: {
        deep: true,
        handler() {
          this.getDates();
        }
      }
    }
  }

</script>
<style scoped>
  th, td{
    padding:        3px     !important;
    text-align:     center  !important;
    vertical-align: middle  !important;
  }
  tr {
    line-height: 1.3;
  }
  th {
    line-height: 1.0;
  }
  label {
    width:  100%;
    margin: 0;
  }
  tr:hover {
    background-color: #eeeeee;
  }
  * {
    font-size: 0.75rem;
  }
  td {
    padding:      0 3px     !important;
    font-size:    0.9rem;
    font-family:  Tahoma, Geneva, sans-serif
  }
  .greenish {
    background-color: #bcf794;
  }
  .reddish {
    background-color: #ffadad;
  }
  .blocked {
    color:       #777777;
    background-color: #f7f7f7;
  }
  .error {
    color: red
  }
  .success {
    color: green
  }
  .totals {
    background-color: #eeeeee;
  }
  .name-cel {
    display:        block;
    overflow:       hidden;
    white-space:    nowrap;
    font-weight:    bold;
    text-overflow:  ellipsis;
  }
  .w1per {
    width: 1%;
    word-wrap: break-word !important;
  }
  .clickable {
    cursor: pointer;
  }
  .form-button {
    float: right
  }
  

  .card .d-flex .btn-danger {
    padding: 1px 6px;
  }

  .card .d-flex input,
  .search-input {
    background: #F1F1F1;
    border: 0;
    border-radius: 50px;
    padding: 2px 0 2px 10px;
    color: grey;
    height: 22px;
    margin: 0 10px;
  }

  .card .d-flex input::placeholder,
  .search-input::placeholder {
    color: grey;
  }

  .btn-purple {
    background: #696FFC !important;
    color: white;
    border-radius: 50px;
    padding: 3px 0px;
    min-width: 130px;
  }

  @keyframes flickerAnimation {
    0%   { opacity:1; }
    10%  { opacity:0.5; }
    100% { opacity:1; }
  }

  .flicker {
    animation: flickerAnimation .5s infinite;
  }

  .btn-light-green {
    background: rgb(75, 206, 172, .2);
    border-radius: 50px;
    border: 1px solid #4bceac !important;
    padding: 3px 40px;
    min-width: 130px;
    font-size: 14px;
    color: #4bceac !important;
  }
</style>

<style>
  .btn-green button{
    background: #4BCEAC !important;
    border-color: #4BCEAC !important;
    border-radius: 50px;
    padding: 3px 40px;
    min-width: 130px;
    font-size: 14px;
    white-space: nowrap;
  }

  .btn-red button {
    background: #DB504A !important;
    color: white;
    border-radius: 50px;
    padding: 3px 40px !important;
    min-width: 130px;
    white-space: nowrap;
  }

  .smart-bid-campaign-btn {
    margin: 0 0 0 20px !important;
    text-align: center !important;
    padding: 3px 20px !important;
  }

  .multi-section {
    margin-top: 0;
  }

  .form form .form-button button {
    background: #696FFC !important;
    color: white;
    border-radius: 50px;
    padding: 3px 0px;
    min-width: 130px;
    border: #696FFC !important;
    white-space: nowrap;
  }

  td .form-button button {
    padding: 3px 20px;
    min-width: unset;
  }

  .vertical-line {
    background: lightgray;
    margin: 0 20px;
    min-height: 25px;
    width: 1px;
  }

  .card .total {
    max-width: 110px;
    line-height: 14px;
  }
</style>


