<template>
  <vs-card>
    <div>
      <span :class="feedbackObject.class">{{feedbackObject.text}}</span>
    </div>
    <component v-bind:is="inputComponent" :feedbackObject="feedbackObject" component="inputComponent"/>
  </vs-card>
</template>

<script>
  export default {
    props: {
      platform: String,
      sub_menu: String
    },
    data(){
      return {
        inputComponent: null,
        feedbackObject: { class: 'success', text: '' }
      }
    },
    mounted(){
      this.inputComponent = this.getInputComponent();
    },
    methods: {
      getInputComponent(){
        return () => import(`./Settings/${this.platform}/${this.sub_menu}.vue`)
      },
      onSubmit(){}
    },
    watch: {
      sub_menu: function(){
        this.feedbackObject.text  = '';
        this.inputComponent       = this.getInputComponent();
      }
    }
  }
</script>
<style scoped>
  .error {
    color: red
  }
  .success {
    color: green
  }
</style>
